<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="teachersStore.teacher.d_nameLast + ', ' +
      teachersStore.teacher.d_nameFirst" />

      <!--Function Bar-->
      <teacher-function-bar />

      <!--Task List-->
      <tasks :key="teachersStore.teacher.id" :taskSection="5" />

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleBar from '@/components/TitleBar';
import TeacherFunctionBar from '@/components/TeacherFunctionBar';
import Tasks from '@/components/Tasks';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'TeacherTasks',
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: (state) => state.teachersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
	  TeacherFunctionBar,
	  Tasks,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
